// Row 1 // 
export enum Row1 {
    TITLE="OUR JOURNEY",
    PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
}

export enum Row2 {
    TITLE="OUR MISSION",
    SUBTITLE_1="Redefining the Way",
    SUBTITLE_2="We Move",
    PARAGRAPH_1="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you.",
    PARAGRAPH_2="This is a great space to write a long text about your company and your services. You can use this space to go into a little more detail about your company. Talk about your team and what services you provide."
}

export enum Row3 {
    TITLE="Our Journey So Far",
        // Cell 1 //
        CELL_1_YEAR="2022",
        CELL_1_TITLE="ARRIS IS FOUNDED",
        CELL_1_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.",
        // Cell 2 //
        CELL_2_YEAR="2023",
        CELL_2_TITLE="FLAGSHIP SOFTWARE LAUNCH",
        CELL_2_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.",
        // Cell 3 //
        CELL_3_YEAR="2022",
        CELL_3_TITLE="ARRIS IS FOUNDED",
        CELL_3_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.",
        // Cell 4 //
        CELL_4_YEAR="2023",
        CELL_4_TITLE="FLAGSHIP SOFTWARE LAUNCH",
        CELL_4_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.",
        // Cell 5 //
        CELL_5_YEAR="2022",
        CELL_5_TITLE="ARRIS IS FOUNDED",
        CELL_5_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font.",
        // Cell 6 //
        CELL_6_YEAR="2023",
        CELL_6_TITLE="FLAGSHIP SOFTWARE LAUNCH",
        CELL_6_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font."
}

export enum Row4 {
    TITLE="News",
    SUBTITLE_1="Arris In The Press"
}

