import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { mediumAreaStyle } from "styles/general";
import { footerItemStyle } from "styles/footer";
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MiniLogo from "static/ArrisMiniLogo.webp";
import { text_primary } from "styles/theme";
import { white_color } from "styles/theme";

export default function Footer() {

    return (
        <Box>
            <Box sx={mediumAreaStyle}>
                <Grid container alignItems="center">
                    <Grid item xs={2} sx={footerItemStyle}></Grid>
                    <Grid item xs={10} sx={footerItemStyle}>
                        <img src={MiniLogo} height="50" width="100" style={{marginLeft: -12}} />
                    </Grid>
                    <Grid item xs={2} sx={footerItemStyle}></Grid>
                    <Grid item xs={2} sx={footerItemStyle}>
                        <Button>
                            Technology
                        </Button>
                    </Grid>
                    <Grid item xs={2} sx={footerItemStyle}>
                        <Typography variant="body1">
                            Tel: 123-456-7890
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={footerItemStyle}>
                    </Grid>
                    <Grid item xs={3} sx={footerItemStyle}>
                        <Typography variant="h5">
                            Sign Up
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={footerItemStyle}></Grid>
                    <Grid item xs={2} sx={footerItemStyle}>
                        <Button>
                            About
                        </Button>
                    </Grid>
                    <Grid item xs={2} sx={footerItemStyle}>
                        <Typography variant="body1">
                            Email: support@arrisstone.com
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={footerItemStyle}>
                    </Grid>
                    <Grid item xs={3} sx={footerItemStyle}>
                        <Typography variant="body1">
                            Join our growing team
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={footerItemStyle}></Grid>
                    <Grid item xs={2} sx={footerItemStyle}>
                        <Button>
                            Careers
                        </Button>
                    </Grid>
                    <Grid item xs={2} sx={footerItemStyle}>
                        <Typography variant="body1">
                            123 Street
                            Edmonton, AB
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sx={footerItemStyle}></Grid>
                    <Grid item xs={3} sx={footerItemStyle}>
                        <Button variant="contained">
                            Try Now
                        </Button>
                    </Grid>
                </Grid>
            </Box> 
            <Box>
                <Grid container>
                    <Grid item xs={2} sx={{paddingBottom: 6}}></Grid>
                    <Grid item xs={5}>
                        <Typography variant="body1" sx={{paddingBottom: 6}}>
                            @2022 Arris
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        
                    </Grid>
                    <Grid item xs={2}>
                        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
                            <IconButton>
                                <FacebookIcon sx={{color: text_primary}} />
                            </IconButton>
                            <IconButton>
                                <TwitterIcon sx={{color: text_primary}} />
                            </IconButton>
                            <IconButton>
                                <InstagramIcon sx={{color: text_primary}} />
                            </IconButton>
                        </Stack>
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Box>
        </Box>
  );
}
