import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useAppDispatch } from 'hooks/api';
import { useAppSelector } from 'hooks/api';
import { getConfirmation } from "api/actions/confirmation";
import { postConfirmation } from "api/actions/confirmation";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { error_color, text_primary } from "styles/theme";
import { app_url } from "settings";

export default function AccountConfirmation() {
    const dispatch = useAppDispatch();
    const { error } = useAppSelector((state) => state.authentication);
    const { account_confirmation_complete } = useAppSelector((state) => state.authentication);
    const { uuid } = useParams();
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const [local_error, setError] = useState<string>("");
    const [has_error, setHasError] = useState<boolean>(false);

    useEffect(() => {
        if(uuid){
            dispatch(getConfirmation(uuid));
        }
    }, [dispatch]);

    const handlePassword = (input: string) => {
        setPassword(input);
        setError("");
        setHasError(false);
    }

    const handlePassword2 = (input: string) => {
        setPassword2(input);
        setError("");
        setHasError(false);
    }

    const activate = () => {
        if(password !== password2){
            setError("Passwords do not match");
            setHasError(true);
            return;
        }

        if(password.length < 8){
            setError("Password must be at least 8 characters");
            setHasError(true);
            return;
        }

        if(uuid){
            dispatch(postConfirmation(uuid, {
                password: password,
                password2: password2
            }));
        }
        else{
            // no uuid, set as resend
        }
    }

    const redirectPage = () => {
        window.location.replace(app_url + "login");
    }

    return (
        <Box sx={{padding: "20vh"}}>
        {
            account_confirmation_complete ?
                <Stack>
                    <Typography variant="h6" sx={{paddingLeft: 3, paddingBottom: 0.5, color: text_primary}}>
                        Your password has been updated. Please login to complete your registration.
                    </Typography>
                    <Button 
                        onClick={redirectPage}
                        variant="contained" 
                        sx={{width: 150, marginTop: 1.5, marginLeft: 3}}>
                        Login
                    </Button>
                </Stack> :
                <Stack>
                    <Typography variant="h6" sx={{paddingLeft: 3, paddingBottom: 0.5, color: text_primary}}>
                        Thank you for confirming your email
                    </Typography>
                    {
                        has_error || error === "" ?
                        <Typography variant="body2" sx={{padding: 3, paddingTop: 0.5, color: has_error ? error_color : text_primary}}>
                            {has_error ? local_error : "Please enter a password for your account"}
                        </Typography> :
                        <Typography variant="body2" sx={{padding: 3, paddingTop: 0.5, color: error_color}}>
                            {error}
                        </Typography>
                    }
                    <TextField 
                        type="password"
                        sx={{marginLeft: 3, marginBottom: 1, width: 400}}
                        size="small"
                        error={has_error}
                        onChange={(e) => handlePassword(e.target.value)}
                        label="Password" />
                    <TextField
                        type="password"
                        sx={{marginLeft: 3, width: 400}}
                        size="small"
                        error={has_error}
                        onChange={(e) => handlePassword2(e.target.value)}
                        label="Confirm Password" />
                    <Button 
                        onClick={activate}
                        variant="contained" 
                        sx={{width: 150, marginTop: 1.5, marginLeft: 3}}>
                        Activate
                    </Button>
                </Stack>
        }
        </Box>
    );
}
