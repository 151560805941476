import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { primary_color } from "styles/theme";

interface Props {
    title1: string,
    title2?: string,
    title3?: string,
    paragraph: string,
    navigation: string,
    grid_left?: number,
    grid_right?: number,
    grid_end?: number
}

export default function ReadMoreBox({
    title1,
    title2,
    title3,
    paragraph,
    navigation,
    grid_left=5,
    grid_right=7,
    grid_end=0
}: Props) {
    const navigate = useNavigate();

    return (
        <Grid container item xs={6}>
            <Grid item xs={grid_left}></Grid>
            <Grid item xs={grid_right}>
                <Stack>
                    <Typography variant="h2" sx={{paddingBottom: 1}}>
                        {title1}
                    </Typography>
                    {
                        title2 ?
                        <Typography variant="h2" sx={{paddingBottom: 1}}>
                            {title2}
                        </Typography> : 
                        null
                    }
                    {
                        title3 ?
                        <Typography variant="h2" sx={{paddingBottom: 1}}>
                            {title3}
                        </Typography> :
                        null
                    }
                    <Typography variant="body1" sx={{paddingBottom: 3, paddingTop: 3.5, paddingRight: "30%"}}>
                        {paragraph}
                    </Typography>
                    <Button 
                        onClick={() => navigate(navigation, {replace: false})}
                        endIcon={<ArrowForwardIcon 
                            sx={{height: 26, width: 26, marginLeft: 0.75, paddingLeft: 1, borderLeft: `1px solid ${primary_color}`}}/>}
                        sx={{paddingLeft: 1.25, paddingRight: 1.25, border: `1px solid ${primary_color}`, marginTop: 2}}>
                        Read More
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={grid_end}></Grid>
        </Grid>
  );
}
