import { useEffect } from "react";
import { useAppDispatch } from "hooks/api";
import { setNavbarTransparency } from "api/actions/ui";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ParallaxBanner } from "react-scroll-parallax";
import { Parallax } from "react-scroll-parallax";
import StatementBox from "components/universal/statement_box";
import ReadMoreBox from "components/universal/read_more_box";
import Footer from "components/universal/footer";
import { largeAreaStyle, smallAreaStyle } from "styles/general";
import { largePictureAreaStyle } from "styles/general";
import { primary_color, white_color } from "styles/theme";
import { text_primary } from "styles/theme";
import Pic1 from "static/pic1.webp";
import Pic2 from "static/pic2.webp";
import Pic3 from "static/pic3.webp";
import Pic4 from "static/pic4.jpg";
import Pic5 from "static/pic5.jpg";
import Pic6 from "static/pic6.webp";
import DividerImage from "static/divider.png";
import CambriaImage from "static/cambria.webp";
import CaesarstoneImage from "static/caesarstone.webp";
import HariStonesImage from "static/hari_stones.webp";
import SilstoneImage from "static/silstone.webp";
import { Row1 } from "values/text/home";
import { Row2 } from "values/text/home";
import { Row3 } from "values/text/home";
import { Row4 } from "values/text/home";
import { Row5 } from "values/text/home";
import { Row6 } from "values/text/home";
import { Row7 } from "values/text/home";
import { Row8 } from "values/text/home";
import { Row9 } from "values/text/home";

export default function Home() {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setNavbarTransparency(false));
    }, [dispatch]);
    
    return(
        <Box>
            <Box sx={[largeAreaStyle, {display: "flex", justifyContent: "center", paddingTop: "8vh"}]}>
                <Box 
                    display="flex" 
                    justifyContent="center" 
                    alignItems="center" 
                    sx={{overflow: "hidden", height: 536, width: 956, marginLeft: 0}}>
                    <video height="540" width="960"
                        autoPlay={true} loop={true} muted>
                        <source src="/assets/video/jumbo_video.mp4" type="video/mp4"></source>
                    </video>
                </Box>
            </Box>
            <Box 
                sx={{
                    backgroundImage: `url(${DividerImage})`,
                    backgroundSize: "100% 100%",
                    height: "4vh"
                }}>    
                         
            </Box>
            <Box sx={[largeAreaStyle, {paddingTop: "6vh", paddingBottom: "6vh"}]}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h2" textAlign="center" sx={{textTransform: "uppercase", padding: 5, paddingTop: 0}}>
                            {Row1.TITLE}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2}>
                        <Box 
                            display="flex" 
                            justifyContent="center" 
                            alignItems="center" 
                            sx={{overflow: "hidden", height: 249, width: 249, marginLeft: 3}}>
                            <video autoPlay={true} muted height="250" width="250">
                                <source src="/assets/video/icon1.mp4" type="video/mp4"></source>
                            </video>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                    <Box 
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center" 
                        sx={{overflow: "hidden", height: 249, width: 249, marginLeft: 3}}>
                            <video autoPlay={true} muted height="250" width="250" >
                                <source src="/assets/video/icon2.mp4" type="video/mp4"></source>
                            </video>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                    <Box 
                        display="flex" 
                        justifyContent="center" 
                        alignItems="center" 
                        sx={{overflow: "hidden", height: 249, width: 249, marginLeft: 3}}>
                            <video autoPlay={true} muted height="250" width="250" >
                                <source src="/assets/video/icon3.mp4" type="video/mp4"></source>
                            </video>
                        </Box>
                    </Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={3}></Grid>
                    <Grid item xs={2} sx={{paddingTop: 2}}>
                        <Typography variant="body1" sx={{fontWeight: "bold"}} textAlign="center">
                            {Row1.CELL_1_SUBTITLE}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{paddingTop: 2}}>
                        <Typography variant="body1" sx={{fontWeight: "bold"}} textAlign="center">
                            {Row1.CELL_2_SUBTITLE}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{paddingTop: 2}}>
                        <Typography variant="body1" sx={{fontWeight: "bold"}} textAlign="center">
                            {Row1.CELL_3_SUBTITLE}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2} sx={{padding: 2}}>
                        <Typography variant="body1">
                            {Row1.CELL_1_PARAGRAPH}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{padding: 2}}>
                        <Typography variant="body1">
                            {Row1.CELL_2_PARAGRAPH}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{padding: 2}}>
                        <Typography variant="body1">
                            {Row1.CELL_3_PARAGRAPH}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
            </Box>
            <Box sx={[largePictureAreaStyle, {background: primary_color}]}>
                <Grid container sx={{height: '100%'}}>
                    <StatementBox 
                        title={Row2.TITLE}
                        subtitle1={Row2.SUBTITLE_1}
                        subtitle2={Row2.SUBTITLE_2}
                        subtitle3={Row2.SUBTITLE_3}
                        paragraph={Row2.PARAGRAPH_1}
                        text_color={white_color}
                        background_color={primary_color}
                        border_color={white_color} 
                        paddingTop={10} />
                    <Grid item xs={6}>
                        <Box sx={{height: "100%", width: "100%", overflow: "hidden"}}>
                            <ParallaxBanner 
                                style={{height: "140%", width: "140%"}}
                                layers={[{
                                    image: Pic1,
                                    translateX: [0, -30],
                                    shouldAlwaysCompleteAnimation: true,
                                    expanded: false,
                                }]}>
                            </ParallaxBanner>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={smallAreaStyle}>
                <Grid container>
                    <StatementBox 
                        title={Row3.TITLE}
                        subtitle1={Row3.SUBTITLE_1}
                        subtitle2={Row3.SUBTITLE_2}
                        subtitle3={Row3.SUBTITLE_3} />
                </Grid>
            </Box>
            <Box sx={{height: "75vh"}}>
                <Grid container>
                    <ReadMoreBox 
                        title1={Row4.TITLE_1}
                        title2={Row4.TITLE_2}
                        title3={Row4.TITLE_3}
                        paragraph={Row4.PARAGRAPH_1}
                        navigation={"/"} />
                    <Grid item xs={6} sx={{
                        height: '75vh',
                        backgroundImage: `url(${Pic2})`,
                        backgroundPosition: "top right",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPositionY: "150%",
                    }}>

                    </Grid>
                </Grid>
            </Box>
            <Box sx={{height: "75vh"}}>
                <Grid container sx={{paddingBottom: "10vh", paddingTop: "10vh"}}>
                    <Grid item xs={6} sx={{
                        height: "75vh",
                        backgroundImage: `url(${Pic3})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100% auto",
                    }}>

                    </Grid>
                    <ReadMoreBox 
                        title1={Row5.TITLE_1}
                        title2={Row5.TITLE_2}
                        paragraph={Row5.PARAGRAPH_1}
                        navigation={"/"}
                        grid_left={2}
                        grid_right={6}
                        grid_end={4} />
                </Grid>
            </Box>
            <Box sx={[largePictureAreaStyle, {marginTop: "15vh"}]}>
                <Grid container>
                    <ReadMoreBox 
                        title1={Row6.TITLE_1}
                        title2={Row6.TITLE_2}
                        title3={Row6.TITLE_3}
                        paragraph={Row6.PARAGRAPH_1}
                        navigation={"/"} />
                    
                    <Grid item xs={6} sx={{
                        height: '75vh',
                        backgroundImage: `url(${Pic4})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "100%"
                    }}>

                    </Grid>
                </Grid>
            </Box>
            <Box sx={{
                marginTop: "15vh",
                height: "95vh",
                backgroundImage: `url(${Pic5})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
            }}>
                <Box sx={{height: "100%", width: "100%", overflow: "hidden"}}>
                    <ParallaxBanner 
                        style={{height: "100%"}}
                        layers={[{
                            image: Pic5,
                            translateY: [-45, 50],
                            shouldAlwaysCompleteAnimation: true,
                            expanded: false,
                        }]}>
                            <Parallax translateY={[45, -40]}>
                                <StatementBox 
                                    title={Row7.TITLE}
                                    subtitle1={Row7.SUBTITLE_1}
                                    subtitle2={Row7.SUBTITLE_2}
                                    subtitle3={Row7.SUBTITLE_3}
                                    paragraph={Row7.PARAGRAPH_1}
                                    text_color={white_color}
                                    background_color={primary_color}
                                    border_color={white_color} 
                                    borderRadius={4}
                                    paddingTop={10}
                                    paddingBottom={10}
                                    paddingLeft={5}
                                    marginLeft={-2.5}
                                    marginTop={5}
                                    navigation="/" />
                            </Parallax>
                    </ParallaxBanner>
                </Box>
            </Box>
            <Box sx={{height: "65vh", paddingBottom: "5vh", paddingTop: "10vh"}}>
                <Grid container sx={{height: "100%"}}>
                    <Grid 
                        item 
                        xs={6} 
                        sx={{
                            height: "100%",
                            backgroundImage: `url(${Pic6})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover"
                        }}>

                    </Grid>
                    <Grid container item xs={6} sx={{paddingTop: "10vh", paddingLeft: 10, paddingRight: 10, paddingBottom: 10}}>
                        <Grid item xs={12} sx={{marginBottom: 10}}>
                            <Typography variant="h5" textAlign="left">
                                {Row8.TITLE}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" alignItems="baseline">
                                <Typography variant="h2">
                                    {Row8.CELL_1_NUMBER}
                                </Typography>
                                <Typography variant="h6" sx={{color: text_primary, marginLeft: 0.25}}>
                                    {Row8.CELL_1_NUMBER_TYPE}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction="row" alignItems="baseline">
                                <Typography variant="h2">
                                    {Row8.CELL_2_NUMBER}
                                </Typography>
                                <Typography variant="h6" sx={{color: text_primary, marginLeft: 0.25}}>
                                    {Row8.CELL_2_NUMBER_TYPE}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={0.5} sx={{borderBottom: `3px solid ${text_primary}`}}></Grid>
                        <Grid item xs={2.5} sx={{borderBottom: `1px solid ${text_primary}`}}></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={0.5} sx={{borderBottom: `3px solid ${text_primary}`}}></Grid>
                        <Grid item xs={2.5} sx={{borderBottom: `1px solid ${text_primary}`}}></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6}>
                            <Stack sx={{paddingTop: 2}}>
                                <Typography variant="h6" sx={{color: text_primary}}>
                                    {Row8.CELL_1_DESCRIPTION_1}
                                </Typography>
                                <Typography variant="h6" sx={{color: text_primary}}>
                                    {Row8.CELL_1_DESCRIPTION_2}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack sx={{paddingTop: 2}}>
                                <Typography variant="h6" sx={{color: text_primary}}>
                                    {Row8.CELL_2_DESCRIPTION_1}
                                </Typography>
                                <Typography variant="h6" sx={{color: text_primary}}>
                                    {Row8.CELL_2_DESCRIPTION_2}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} sx={{marginTop: 4}}>
                            <Stack direction="row" alignItems="baseline">
                                <Typography variant="h2">
                                    {Row8.CELL_3_NUMBER}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} sx={{marginTop: 4}}>
                            <Stack direction="row" alignItems="baseline">
                                <Typography variant="h2">
                                    {Row8.CELL_4_NUMBER}
                                </Typography>
                                <Typography variant="h6" sx={{color: text_primary, marginLeft: 0.25}}>
                                    {Row8.CELL_4_NUMBER_TYPE}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={0.5} sx={{borderBottom: `3px solid ${text_primary}`}}></Grid>
                        <Grid item xs={2.5} sx={{borderBottom: `1px solid ${text_primary}`}}></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={0.5} sx={{borderBottom: `3px solid ${text_primary}`}}></Grid>
                        <Grid item xs={2.5} sx={{borderBottom: `1px solid ${text_primary}`}}></Grid>
                        <Grid item xs={3}></Grid>
                        <Grid item xs={6}>
                            <Stack sx={{paddingTop: 2}}>
                                <Typography variant="h6" sx={{color: text_primary}}>
                                    {Row8.CELL_3_DESCRIPTION_1}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack sx={{paddingTop: 2}}>
                                <Typography variant="h6" sx={{color: text_primary}}>
                                    {Row8.CELL_4_DESCRIPTION_1}
                                </Typography>
                                <Typography variant="h6" sx={{color: text_primary}}>
                                    {Row8.CELL_4_DESCRIPTION_2}
                                </Typography>
                            </Stack>
                        </Grid>
                
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{height: "65vh", paddingBottom: "5vh", paddingTop: "10vh", backgroundColor: primary_color}}>
                <Grid container>
                    <StatementBox 
                        title={Row9.TITLE}
                        subtitle1={Row9.SUBTITLE_1}
                        subtitle2={Row9.SUBTITLE_2}
                        paragraph={Row9.PARAGRAPH_1}
                        text_color={white_color}
                        background_color={primary_color}
                        border_color={white_color} 
                        paddingTop={5} />
                    <Grid container item xs={6} sx={{paddingTop: 5}}>
                        <Grid item xs={6}>
                            <Stack alignItems="center">
                                <img src={SilstoneImage}  height="120" width="120" alt="Silestone" />
                                <Typography variant="h6" textAlign="center">
                                    Silestone
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack alignItems="center">
                                <img src={CaesarstoneImage}  height="120" width="120" alt="Caesarstone" />
                                <Typography variant="h6" textAlign="center">
                                    Caesarstone
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack alignItems="center">
                                <img src={CambriaImage}  height="120" width="120" alt="Cambria" />
                                <Typography variant="h6" textAlign="center">
                                    Cambria
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Stack alignItems="center">
                                <img src={HariStonesImage}  height="120" width="120" alt="HariStones" />
                                <Typography variant="h6" textAlign="center">
                                    Tri-Nex
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            <Footer />
        </Box>
    )
}
