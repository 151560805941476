import { useState } from "react";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/api";
import { setNavbarTransparency } from "api/actions/ui";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import StatementBox from "components/universal/statement_box";
import { ParallaxBanner } from "react-scroll-parallax";
import TimelineBox from "./timeline_box";
import Footer from "components/universal/footer";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { white_color } from "styles/theme";
import { text_primary } from "styles/theme";
import AboutPic1 from "static/about1.webp";
import AboutPic2 from "static/About2.jpg";
import { largeAreaStyle } from "styles/general";
import { mediumAreaStyle } from "styles/general";
import { Row1 } from "values/text/about";
import { Row2 } from "values/text/about";
import { Row3 } from "values/text/about";
import { Row4 } from "values/text/about";

export default function About() {
    const dispatch = useAppDispatch();
    const [timeline_index, setTimelineIndex] = useState<number>(0);

    useEffect(() => {
        dispatch(setNavbarTransparency(false));
    }, [dispatch]);

    const incrementTimelineIndex = () => {
        if(timeline_index === 2){
            setTimelineIndex(0);
        }
        else{
            setTimelineIndex(prev => prev + 1);
        }
    }

    const decrementTimelineIndex = () => {
        if(timeline_index === 0){
            setTimelineIndex(2);
        }
        else{
            setTimelineIndex(prev => prev - 1);
        }
    }

    return(
        <Box>
            <Box sx={[mediumAreaStyle, {borderBottom: `1px solid ${text_primary}`}]}>
                <Stack>
                    <Typography variant="h1" textAlign="center" sx={{paddingBottom: 6}}>
                        {Row1.TITLE}
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{
                            fontSize: 26,
                            lineHeight: 1.75,
                            width: "40%",
                            marginLeft: "auto",
                            marginRight: "auto"
                        }} textAlign="center">
                        {Row1.PARAGRAPH}
                    </Typography>
                </Stack>
            </Box>
            <Box sx={[largeAreaStyle, {borderBottom: `1px solid ${text_primary}`}]}>
                <Grid container sx={{height: '100%'}}>
                    <StatementBox 
                        title={Row2.TITLE}
                        subtitle1={Row2.SUBTITLE_1}
                        subtitle2={Row2.SUBTITLE_2}
                        paragraph={Row2.PARAGRAPH_1}
                        paragraph2={Row2.PARAGRAPH_2}
                        text_color={text_primary}
                        background_color={white_color}
                        border_color={text_primary} />
                    <Grid item xs={6} sx={{
                        WebKitBorderRadius: 4,
                        MozBorderRadius: 5,
                        backgroundImage: `url(${AboutPic1})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        borderRadius: 4,
                        backgroundPositionY: "10%",
                    }}>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{height: "80vh", paddingTop: "10vh", paddingBottom: "5vh"}}>
                <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                     <Typography variant="h5" sx={{paddingLeft: 5}}>
                            {Row3.TITLE}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{marginTop: 1.5}}>
                        <Box 
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{height: "70vh"}}>
                            <IconButton>
                                <ArrowBackIosIcon onClick={decrementTimelineIndex}/>
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid container item xs={10} sx={{marginTop: 3}}>
                        <Grid item xs={12} sx={{height: "30vh", padding: "1vh"}}>
                            <Box 
                                sx={{height: "100%"}}
                                display="flex"
                                alignItems="center"
                                justifyContent="center">
                                    {
                                        timeline_index === 0 ?
                                        <TimelineBox 
                                            year={Row3.CELL_1_YEAR}
                                            title={Row3.CELL_1_TITLE}
                                            paragraph={Row3.CELL_1_PARAGRAPH} /> :
                                        null
                                    }
                                    {
                                        timeline_index === 1 ?
                                        <TimelineBox 
                                            year={Row3.CELL_3_YEAR}
                                            title={Row3.CELL_3_TITLE}
                                            paragraph={Row3.CELL_3_PARAGRAPH}
                                            shift_right={true} /> :
                                        null
                                    }
                                    {
                                        timeline_index === 2 ?
                                        <TimelineBox 
                                            year={Row3.CELL_5_YEAR}
                                            title={Row3.CELL_5_TITLE}
                                            paragraph={Row3.CELL_5_PARAGRAPH} /> :
                                        null
                                    }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{height: "5vh"}}>
                            <Box 
                                sx={{height: "100%"}}
                                display="flex"
                                alignItems="center"
                                justifyContent="center">
                                <Box
                                    sx={{height: "1px", backgroundColor: text_primary, width: "100%"}}></Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sx={{height: "30vh", padding: "1vh"}}>
                            <Box 
                                sx={{height: "100%"}}
                                display="flex"
                                alignItems="center"
                                justifyContent="center">
                                    {
                                        timeline_index === 0 ?
                                        <TimelineBox 
                                            year={Row3.CELL_2_YEAR}
                                            title={Row3.CELL_2_TITLE}
                                            paragraph={Row3.CELL_2_PARAGRAPH}
                                            shift_right={true} /> :
                                        null
                                    }
                                    {
                                        timeline_index === 1 ?
                                        <TimelineBox 
                                            year={Row3.CELL_4_YEAR}
                                            title={Row3.CELL_4_TITLE}
                                            paragraph={Row3.CELL_4_PARAGRAPH} /> :
                                        null
                                    }
                                    {
                                        timeline_index === 2 ?
                                        <TimelineBox 
                                            year={Row3.CELL_6_YEAR}
                                            title={Row3.CELL_6_TITLE}
                                            paragraph={Row3.CELL_6_PARAGRAPH}
                                            shift_right={true} /> :
                                        null
                                    }
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={1} sx={{marginTop: 1.5}}>
                        <Box 
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={{height: "70vh"}}>
                            <IconButton>
                                <ArrowForwardIosIcon onClick={incrementTimelineIndex}/>
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>    
            </Box>
            <Box sx={{height: "100vh", overflow: "hidden"}}>
                <ParallaxBanner 
                    style={{height: "125%", width: "125%"}}
                    layers={[{
                        image: AboutPic2,
                        translateY: [-45, 50],
                        shouldAlwaysCompleteAnimation: true,
                        expanded: false,
                    }]}></ParallaxBanner>
            </Box>
            <Box  sx={[mediumAreaStyle, {backgroundColor: "#111111"}]}>
                <StatementBox 
                    title={Row4.TITLE}
                    subtitle1={Row4.SUBTITLE_1}
                    text_color={white_color}
                    background_color="#111111"
                    border_color={white_color} />
            </Box>
            <Footer />
        </Box>
    )
}
