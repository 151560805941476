import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { text_primary } from "styles/theme";
import { white_color } from "styles/theme";

interface Props {
    title: string,
    subtitle1?: string,
    subtitle2?: string,
    subtitle3?: string,
    paragraph?: string,
    paragraph2?: string,
    text_color?: string,
    background_color?: string,
    border_color?: string,
    borderRadius?: number,
    paddingTop?: number,
    paddingLeft?: number,
    paddingBottom?: number,
    marginLeft?: number,
    marginTop?: number,
    navigation?: string
}

export default function StatementBox({
    title,
    subtitle1,
    subtitle2,
    subtitle3,
    paragraph,
    paragraph2,
    text_color=text_primary,
    background_color=white_color,
    border_color=text_primary,
    borderRadius=0,
    paddingTop=0,
    paddingBottom=0,
    paddingLeft=0,
    marginLeft=0,
    marginTop=0,
    navigation
}: Props) {
    const navigate = useNavigate();

    return (
        <Grid container item xs={6}>
            <Grid 
                item
                xs={4}
                sx={{
                    paddingTop: `${paddingTop}vh`,
                    paddingLeft: `${paddingLeft}vh`,
                    paddingBottom: `${paddingBottom}vh`,
                    marginLeft: `${marginLeft}vh`,
                    marginTop: `${marginTop}vh`
                }}></Grid>
            <Grid 
                item
                xs={8}
                sx={{
                    borderRadius: borderRadius,
                    backgroundColor: background_color,
                    paddingTop: `${paddingTop}vh`,
                    paddingLeft: `${paddingLeft}vh`,
                    paddingBottom: `${paddingBottom}vh`,
                    marginLeft: `${marginLeft}vh`,
                    marginTop: `${marginTop}vh`
                }}>
                <Stack>
                    <Typography 
                        variant="h6" 
                        sx={{
                            color: text_color,
                            borderLeft: `1px solid ${border_color}`,
                            paddingLeft: 10,
                            paddingRight: 8,
                            paddingBottom: 5
                        }}>
                        {title}
                    </Typography>
                    {
                        subtitle1 ?
                        <Typography 
                            variant="h5" 
                            sx={{
                                color: text_color,
                                borderLeft: `3px solid ${border_color}`,
                                paddingLeft: 10,
                                paddingRight: 8,
                                paddingBottom: subtitle2 || subtitle3 ? 1 : 4
                            }}>
                            {subtitle1}
                        </Typography> :
                        null
                    }
                    {
                        subtitle2 ?
                        <Typography 
                            variant="h5" 
                            sx={{
                                color: text_color,
                                borderLeft: `3px solid ${border_color}`,
                                paddingLeft: 10,
                                paddingRight: 8,
                                paddingBottom: subtitle3 ? 1 : 2
                            }}>
                            {subtitle2}
                        </Typography> :
                        null
                    }
                    {
                        subtitle3 ?
                        <Typography 
                            variant="h5" 
                            sx={{
                                color: text_color,
                                borderLeft: `3px solid ${border_color}`,
                                paddingLeft: 10,
                                paddingRight: 8,
                                paddingBottom: 2
                            }}>
                            {subtitle3}
                        </Typography> :
                        null
                    }
                    {
                        paragraph ?
                        <Typography 
                            variant="body1"
                            sx={{
                                color: text_color,
                                borderLeft: `1px solid ${border_color}`,
                                paddingTop: 4,
                                paddingLeft: 10,
                                paddingRight: "30%"
                            }}>
                            {paragraph}
                        </Typography> :
                        null
                    }
                    
                    {
                        paragraph2 ?
                        <Typography
                            variant="body1"
                            sx={{
                                color: text_color,
                                borderLeft: `1px solid ${border_color}`,
                                paddingTop: 4,
                                paddingLeft: 10,
                                paddingRight: "30%"
                            }}>
                            {paragraph2}
                        </Typography> :
                        null
                    }
                    {
                        navigation ?
                            <Button 
                                onClick={() => navigate(navigation, {replace: false})}
                                endIcon={<ArrowForwardIcon 
                                    sx={{height: 26, width: 26, marginLeft: 0.75, paddingLeft: 1, borderLeft: `1px solid ${white_color}`}}/>}
                                sx={{
                                    marginLeft: 10,
                                    paddingLeft: 1.25,
                                    paddingRight: 1.25,
                                    border: `1px solid ${white_color}`,
                                    color: white_color,
                                    marginTop: 4}}>
                                Read More
                            </Button> :
                        null
                    }
                </Stack>
            </Grid>
        </Grid>
  );
}
