import { useEffect } from "react";
import { useAppDispatch } from "hooks/api";
import { useAppSelector } from "hooks/api";
import { setNavbarTransparency } from "api/actions/ui";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommentBox from "components/universal/comment_box";
import StatementBox from "components/universal/statement_box";
import Footer from "components/universal/footer";
import { black_color, white_color } from "styles/theme";
import { text_primary } from "styles/theme";
import FabricatorHero from "static/fab_hero.webp";
import Fab1 from "static/fab1.webp";
import Fab2 from "static/fab2.webp";
import { heroTransparentAreaStyle } from "styles/general";
import { largePictureAreaStyle } from "styles/general";
import { smallAreaStyle } from "styles/general";
import { Hero } from "values/text/fabricator";
import { Growth } from "values/text/fabricator";
import { GrowthDetails } from "values/text/fabricator";
import { Streamline } from "values/text/fabricator";
import { StreamlineDetails } from "values/text/fabricator";

export default function Fabricator() {
    const dispatch = useAppDispatch();
    const { transparent } = useAppSelector((state) => state.ui);


    useEffect(() => {
        dispatch(setNavbarTransparency(true));
    }, [dispatch]);

    useEffect(() => {
        const handleScroll = () => {
            if(window.scrollY / window.innerHeight > .90 && transparent){
                dispatch(setNavbarTransparency(false));
            }
            else if(window.scrollY / window.innerHeight < .90 && !transparent){
                dispatch(setNavbarTransparency(true));
            }
        }

        window.addEventListener("scroll", handleScroll, false);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [dispatch, setNavbarTransparency(false)]);

    return(
        <Box>
            <Box sx={[heroTransparentAreaStyle, {
                marginTop: "-10vh",
                backgroundImage: `url(${FabricatorHero})`,
                backgroundSize: "100% auto"
            }]}>
                <Stack>
                    <Typography 
                        variant="h2"
                        textAlign="center"
                        sx={{paddingLeft: "25%", paddingRight: "25%", lineHeight: 2, color: white_color, paddingTop: "45vh"}}>
                        {Hero.TITLE}
                    </Typography>
                </Stack>
            </Box>
            <Box sx={largePictureAreaStyle}>
                <Grid container sx={{height: '100%'}}>
                    <StatementBox 
                        title={Growth.TITLE}
                        subtitle1={Growth.PARAGRAPH}
                        text_color={text_primary}
                        background_color={white_color}
                        border_color={text_primary}
                        paddingTop={10}
                        paddingBottom={10} />
                    <Grid item xs={6} sx={{
                        WebKitBorderRadius: 4,
                        MozBorderRadius: 5,
                        backgroundImage: `url(${Fab1})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        borderRadius: 4,
                        backgroundPositionY: "10%",
                    }}>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={smallAreaStyle}>
                <Grid container>
                    <CommentBox 
                        title={GrowthDetails.TITLE_1}
                        paragraph={GrowthDetails.PARAGRAPH_1}
                        text_color={text_primary}/>
                    <CommentBox 
                        title={GrowthDetails.TITLE_2}
                        paragraph={GrowthDetails.PARAGRAPH_2}
                        text_color={text_primary}
                        right={true}/>
                </Grid>
            </Box>
            <Box  sx={[largePictureAreaStyle, {backgroundColor: black_color}]}>
                <Grid container sx={{height: "100%"}}>
                    <StatementBox 
                        title={Streamline.TITLE}
                        subtitle1={Streamline.PARAGRAPH}
                        text_color={white_color}
                        background_color={black_color}
                        border_color={white_color}
                        paddingTop={10} />
                    <Grid item xs={6} sx={{
                        backgroundImage: `url(${Fab2})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPositionY: "10%"
                        }}>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={[smallAreaStyle, {backgroundColor: black_color}]}>
                <Grid container>
                    <CommentBox 
                        title={StreamlineDetails.TITLE_1}
                        paragraph={StreamlineDetails.PARAGRAPH_1}
                        text_color={white_color}/>
                    <CommentBox 
                        title={StreamlineDetails.TITLE_2}
                        paragraph={StreamlineDetails.PARAGRAPH_2}
                        text_color={white_color}
                        right={true}/>
                </Grid>
            </Box>
            <Footer />
        </Box>
    )
}
