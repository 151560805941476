import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Grid";

interface Props {
    title: string,
    paragraph: string,
    text_color: string,
    right?: boolean
}

export default function CommentBox({
    title,
    paragraph,
    text_color,
    right
}: Props) {
    return (
        <Grid container item xs={6}>
            {
                right ?
                <Grid item xs={2}></Grid> : 
                <Grid item xs={4}></Grid>
            }
            <Grid item xs={8}>
                <Stack>
                    <Typography 
                        variant="h6" 
                        sx={{
                            color: text_color,
                            paddingLeft: 10,
                            paddingRight: 8,
                            paddingBottom: 2
                        }}>
                        {title}
                    </Typography>
                    {
                        paragraph ?
                        <Typography 
                            variant="body1"
                            sx={{
                                color: text_color,
                                paddingLeft: 10,
                                paddingRight: "10%",
                                lineHeight: 2
                            }}>
                            {paragraph}
                        </Typography> :
                        null
                    }
                </Stack>
            </Grid>
            {
                right ?
                <Grid item xs={2}></Grid> : 
                null
            }
        </Grid>
  );
}
