import { useEffect } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { text_primary } from "styles/theme";
import { animated, useSpring } from "@react-spring/web";

interface Props {
    year: string,
    title: string,
    paragraph: string,
    shift_right?: boolean
}

export default function TimelineBox({
    year,
    title,
    paragraph,
    shift_right
}: Props) {
    const [styles, api] = useSpring(
        () => ({
          x: 0
        }), []
    );

    useEffect(() => {
        api.start({
            config: {duration: 400},
            to: [
              {x: shift_right ? 150 : -150},
            ],
        });
    }, []);

    return (
        <animated.div
            className="spring-box"
            style={{
                ...styles
            }}>
            <Box
                sx={{
                    transition: "margin-right 2s ease-in-out",
                    borderRadius: 5,
                    border: `1px solid ${text_primary}`,
                    width: 600,
                    height: '100%'
                }}>
                <Stack sx={{padding: 5}}>
                    <Typography variant="h2" sx={{color: "#e15f09", marginBottom: 2}}>
                        {year}
                    </Typography>
                    <Box sx={{marginBottom: 2.5, backgroundColor: text_primary, width: 85, height: "1px"}}></Box>
                    <Typography variant="h6" sx={{color: text_primary, marginBottom: 2}}>
                        {title}
                    </Typography>
                    <Typography variant="body1">
                        {paragraph}
                    </Typography>
                </Stack>
            </Box>
        
        </animated.div>
  );
}
