import { useEffect } from "react";
import { useAppDispatch } from "hooks/api";
import { setNavbarTransparency } from "api/actions/ui";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import Footer from "components/universal/footer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { text_primary } from "styles/theme";
import { primary_color } from "styles/theme";
import { well_color } from "styles/theme";
import { white_color } from "styles/theme";

export default function Plans() {
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        dispatch(setNavbarTransparency(false));
    }, [dispatch]);

    return(
        <Box>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <Typography variant="h2" textAlign="center" sx={{paddingTop: "10vh"}}>
                        Choose your pricing plan
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" textAlign="center" sx={{paddingTop: "2vh", textTransform: "none"}}>
                        Find one that works for you
                    </Typography>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={3} sx={{paddingTop: "10vh"}}>
                    <Paper sx={{width: 400, padding: 4, paddingTop: 4, paddingBottom: 4}} elevation={5}>
                        <Stack alignItems="center">
                            <Typography variant="h6" sx={{color: text_primary}} textAlign="center">
                                Flexible Plan
                            </Typography>
                            <Stack direction="row" sx={{paddingTop: 1}} justifyContent="center">
                                <Typography variant="body2">
                                    $
                                </Typography>
                                <Typography variant="h1">
                                    199
                                </Typography>
                            </Stack>
                            <Typography variant="body2" textAlign="center">
                                Every month
                            </Typography>
                            <Typography variant="body1" textAlign="center" sx={{fontSize: 15, paddingTop: 1}}>
                                Billed Monthly
                            </Typography>
                            <Typography variant="body2" textAlign="center" sx={{paddingTop: 5}}>
                                30 day free trial
                            </Typography>
                            <Button variant="contained" sx={{marginTop: 4, marginBottom: 1, width: 200, padding: 1}}>
                                Start Free Trial
                            </Button>
                        </Stack>
                        <Stack alignItems="left">
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    Access to all drawing and cost projection tools Zero fees
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    Zero fees for setup or implementation
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    Comprehensive support via phone, email, and Zoom without any
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    A vast repository of knowledge and resources
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    Complete features for task scheduling and project management
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    Freedom to cancel at any time
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}}>
                                <Typography variant="body2" textAlign="center">
                                   <span>&nbsp;</span>
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} sx={{paddingTop: "10vh"}}>
                <Paper sx={{width: 480}} elevation={5}>
                        <Box sx={{position: "absolute"}}>
                            <Box 
                                sx={{
                                    position: "relative",
                                    left: 185,
                                    top: -20,
                                    width: 100,
                                    height: 35,
                                    backgroundColor: primary_color,
                                    marginBottom: 2
                                }}
                                display="flex"
                                justifyContent="center"
                                alignItems="center">
                                <Typography variant="body2" sx={{color: white_color}}>
                                    Best Value
                                </Typography>
                            </Box>
                        </Box>
                        <Stack 
                            alignItems="center"
                            sx={{
                                backgroundColor: well_color,
                                paddingBottom: 1.5,
                                paddingTop: 4,
                                paddingRight: 4,
                                paddingLeft: 4
                            }}>
                            <Typography variant="h6" sx={{color: text_primary}}>
                                Momentum Plan
                            </Typography>
                            <Stack direction="row" sx={{paddingTop: 1}} justifyContent="center">
                                <Typography variant="body2">
                                    $
                                </Typography>
                                <Typography variant="h1">
                                    165
                                </Typography>
                            </Stack>
                            <Typography variant="body2" textAlign="center">
                                Every month
                            </Typography>
                            <Typography variant="body1" textAlign="center" sx={{fontSize: 15, paddingTop: 1}}>
                                Billed Yearly
                            </Typography>
                            <Typography variant="body2" textAlign="center" sx={{paddingTop: 5}}>
                                30 day free trial
                            </Typography>
                            <Button variant="contained" sx={{marginTop: 4, marginBottom: 1, width: 200, padding: 1}}>
                                Start Free Trial
                            </Button>
                        </Stack>
                        <Stack sx={{paddingBottom: 4, paddingRight: 4, paddingLeft: 4}} justifyContent="flex-start">
                            <Stack direction="row" sx={{paddingTop: 1.5}}>
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" textAlign="center">
                                    Get 2 months FREE, when you pay annually ($408 in SAVINGS)
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    Access to all drawing and cost projection tools
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    Zero fees for setup or implementation
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    Comprehensive support via phone, email, and Zoom without any
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    A vast repository of knowledge and resources
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    Complete features for task scheduling and project management
                                </Typography>
                            </Stack>
                            <Stack direction="row" sx={{paddingTop: 3}} justifyContent="flex-start">
                                <Icon color="primary" fontSize="small">
                                    <CheckCircleIcon fontSize="small"/>
                                </Icon>
                                <Typography variant="body2" sx={{marginLeft: 2}}>
                                    Freedom to cancel at any time
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
            <Footer />
        </Box>
    )
}
