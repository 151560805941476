import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "hooks/api";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import RegistrationComponent from "components/registration/registration";
import LogoIcon from "static/logo_icon.png";
import { primary_color } from "styles/theme";
import { white_color } from "styles/theme";
import { home_url } from "components/router/endpoints";
import { about_url } from "components/router/endpoints";
import { fabricator_url } from "components/router/endpoints";
import { merchant_url } from "components/router/endpoints";
import { plans_url } from "components/router/endpoints";
import { app_url } from "settings";
import { transparent_color } from "styles/theme";

export default function NavBar() {
    const navigate = useNavigate();
    const { transparent } = useAppSelector((state) => state.ui);
    const [open, setOpen] = useState<boolean>(false);

    const navigateLogin = () => {
        window.location.href = app_url + "login";
    }

    return (
        <AppBar position="sticky" sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            paddingLeft: 10,
            paddingRight: 10,
            marginLeft: 0,
            left: 0,
            right:0,
            top: 0,
            height: "10vh",
            backgroundColor: transparent ? transparent_color : white_color
        }}>
            <Toolbar disableGutters={true} sx={{backgroundColor: transparent_color}}>
                <img 
                    style={{cursor: "pointer"}}
                    onClick={() => navigate(home_url, {replace: false})}
                    src={LogoIcon} 
                    height="45%" />
                <Typography 
                    variant="h4" 
                    sx={{marginLeft: 3, cursor: "pointer"}}
                    onClick={() => navigate(home_url, {replace: false})}>
                        A r r i s
                </Typography>
                <Box display="flex" alignItems="center">
                    
                    
                </Box>
                <Box sx={{ flexGrow: 1 }}></Box>  
                <Button
                    onClick={() => navigate(plans_url, {replace: false})}
                    sx={{marginRight: 2}}>
                    Plans
                </Button>
                <Button sx={{marginRight: 2}}>
                    Technology
                </Button>
                <Button
                    onClick={() => navigate(merchant_url, {replace: false})}
                    sx={{marginRight: 2}}>
                    For Merchants
                </Button>
                <Button
                    onClick={() => navigate(fabricator_url, {replace: false})}
                    sx={{marginRight: 2}}>
                    For Fabricators
                </Button>
                <Button 
                    onClick={() => navigate(about_url, {replace: false})}
                    sx={{marginRight: 2}}>
                    About
                </Button>
                <Button 
                    onClick={navigateLogin}
                    sx={{marginRight: 2}}>
                    Login
                </Button>
                <Button 
                    size="small"
                    variant="contained"  
                    onClick={() => setOpen(true)}
                    sx={{
                        border: `1px solid ${primary_color}`,
                        paddingLeft: 3, 
                        paddingRight: 3,
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                        ":hover": {
                            bgcolor: white_color,
                            color: primary_color
                        }
                    }}>
                    Try now
                </Button>
                <RegistrationComponent 
                    open={open}
                    setOpen={setOpen} />
            </Toolbar>
        </AppBar>
  );
}
