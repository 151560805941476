// Row 1 // 
export enum Row1 {
    TITLE="Why Arris?",
        // Cell 1 // 
        CELL_1_SUBTITLE="Seamless Migration",
        CELL_1_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It's easy.",
        // Cell 2 //
        CELL_2_SUBTITLE="24/5 Support",
        CELL_2_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It's easy.",
        // Cell 3 //
        CELL_3_SUBTITLE="Effortless Drawing",
        CELL_3_PARAGRAPH="I'm a paragraph. Click here to add your own text and edit me. It's easy.",
}

// Row 2 //
export enum Row2 {
    TITLE="Vision",
        // Subtitles //
        SUBTITLE_1="We’re Changing the",
        SUBTITLE_2="Way the Granite",
        SUBTITLE_3="Industry Operates",
        // Paragraphs //
        PARAGRAPH_1="Our vision is to bridge the chasm between fabricators and suppliers by providing an edge-to-edge solution. From drawing the blueprint to quoting to sending the job to the most competitive fabricator. You can do it all in one place and in seconds!"
}

// Row 3 //
export enum Row3 {
    TITLE="Services",
        // Subtitles //
        SUBTITLE_1="We Deliver Exceptional",
        SUBTITLE_2="Products and Services",
        SUBTITLE_3="Around the World"
}

// Row 4 //
export enum Row4 {
    TITLE_1="GAIN ACCESS TO",
    TITLE_2="HUNDREDS OF",
    TITLE_3="FABRICATORS",
    PARAGRAPH_1="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
}

// Row 5 //
export enum Row5 {
    TITLE_1="GET THE BEST",
    TITLE_2="MATERIAL PRICING",
    PARAGRAPH_1="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
}
   
// Row 6 //
export enum Row6 {
    TITLE_1="COMPLETE",
    TITLE_2="CONTROL ON",
    TITLE_3="PRICING",
    PARAGRAPH_1="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
}

export enum Row7 {
    TITLE="WHY ARRIS",
        // Subtitle //
        SUBTITLE_1="An edge-to-edge",
        SUBTITLE_2="solution built by",
        SUBTITLE_3="fabricators & suppliers",
        PARAGRAPH_1="I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you."
}

export enum Row8 {
    TITLE="Average Savings Per Month",
        // Cell 1 //
        CELL_1_NUMBER="168",
        CELL_1_NUMBER_TYPE="HOURS",
        CELL_1_DESCRIPTION_1="WAITING FOR",
        CELL_1_DESCRIPTION_2="FABRICATORS",
        // Cell 2 //
        CELL_2_NUMBER="56",
        CELL_2_NUMBER_TYPE="HOURS",
        CELL_2_DESCRIPTION_1="GENERATING",
        CELL_2_DESCRIPTION_2="QUOTES",
        // Cell 3 //
        CELL_3_NUMBER="$5000",
        CELL_3_NUMBER_TYPE="",
        CELL_3_DESCRIPTION_1="HR COST",
        // Cell 4 //
        CELL_4_NUMBER="73",
        CELL_4_NUMBER_TYPE="HOURS",
        CELL_4_DESCRIPTION_1="FINDING &",
        CELL_4_DESCRIPTION_2="UPDATING PRICES"
}

export enum Row9 {
    TITLE="INDUSTRY",
        // Subtitles //
        SUBTITLE_1="Our",
        SUBTITLE_2="Partners",
        // Paragraphs //
        PARAGRAPH_1="Click here to add your own content and customize the text. This is a great place to tell a story about your company and let your users know a little more about the company's history, the team's background, or any other information you'd like to share."
}

