export const primary_color: string = '#0063fe'; //#477ff7 #0063fe //2d75e4
export const secondary_color: string = '#f6be41'; //FAA41A //C3FF19 //fe9b00 //f5ba51 ABE016  //f6be41 //FFE00D
export const transparent_secondary_color: string = "rgba(254, 155, 0, 0.8)";
export const error_color: string = '#ec503e'; ////#E58E1C FF5419 FF3719 //ec503e //FF4200
export const canvas_error_color: string = "#FF3719"; //FF3719
export const well_color: string = '#e9effe';
export const text_primary: string = '#333333';
export const text_secondary: string = '#202528';
export const black_color: string = "#000000";
export const white_color: string = '#ffffff';
export const grey_color: string = '#888888';
export const dark_grey_color: string = '#757575';
export const light_grey_color: string = "#D4D4D4";
export const transparent_color: string = "rgba(52, 52, 52, 0)";