export enum ActionType {
    NAVBAR_TRANSPARENCY = "NAVBAR_TRANSPARENCY"
}

interface navbarTransparency {
    type: ActionType.NAVBAR_TRANSPARENCY,
    transparent: boolean
}

export type Action = 
    navbarTransparency;