import {combineReducers} from 'redux';
import { authenticationReducer } from "./authentication";
import { uiReducer } from './ui';

const reducers = combineReducers({
    authentication: authenticationReducer,
    ui: uiReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;