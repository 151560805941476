import { hero_area_transparent_nav } from "./values";
import { large_area } from "./values";
import { large_area_no_padding } from "./values";
import { medium_area } from "./values";
import { small_area } from "./values";
import { area_vertical_padding } from "./values";

export const heroTransparentAreaStyle = {
    height: `${hero_area_transparent_nav}vh`,
    paddingTop: `25vh`
}

export const largeAreaStyle = {
    minHeight: `${large_area}vh`,
    paddingTop: `${area_vertical_padding}vh`,
    paddingBottom: `${area_vertical_padding}vh`
}

export const largePictureAreaStyle = {
    height: `${large_area_no_padding}vh`
}

export const mediumAreaStyle = {
    height: `${medium_area}vh`,
    paddingTop: `${area_vertical_padding}vh`,
    paddingBottom: `${area_vertical_padding}vh`
}

export const smallAreaStyle = {
    minHeight: `${small_area}vh`,
    paddingTop: `${area_vertical_padding}vh`,
    paddingBottom: `${area_vertical_padding}vh`
}