import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/api";
import { useAppSelector } from "hooks/api";
import { register } from "api/actions/authentication";
import { resetRegistrationValues } from "api/actions/authentication";
import { Registration } from "types/authentication";
import { RegistrationErrors } from "types/authentication";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import { validateEmail } from "functions/email";
import { validatePhoneNumber } from "functions/phone";
import { error_color, primary_color, white_color } from "styles/theme";
import { formHelperTextStyle } from "styles/registration";
import { password_reset_url } from "components/router/endpoints";

interface Props {
    open: boolean,
    setOpen: (open: boolean) => void
}

export default function RegistrationComponent({open, setOpen}: Props) {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { loading } = useAppSelector((state) => state.authentication);
    const { error } = useAppSelector((state) => state.authentication);
    const { offer_reset } = useAppSelector((state) => state.authentication);
    const { registration_complete } = useAppSelector((state) => state.authentication);
    const [registration, setRegistration] = useState<Registration>({
        email: "",
        first_name: "",
        last_name: "",
        business_name: "",
        phone_number: "",
        address: "",
        city: "",
        province: "",
        postal_code: "",
        is_fabricator: false,
        is_distributor: false
    });

    const [registration_error, setRegistrationError] = useState<RegistrationErrors>({
        email_error: "",
        email_has_error: false,
        first_name_error: "",
        first_name_has_error: false,
        last_name_error: "",
        last_name_has_error: false,
        business_name_error: "",
        business_name_has_error: false,
        address_error: "",
        address_has_error: false,
        city_error: "",
        city_has_error: false,
        postal_code_error: "",
        postal_code_has_error: false,
        phone_number_error: "",
        phone_number_has_error: false
    });

    useEffect(() => {
        dispatch(resetRegistrationValues());
    }, [dispatch]);

    const handlePasswordReset = () => {
        setOpen(false);
        navigate(`/${password_reset_url}`, {replace: false});
    }

    const saveString = (key: string, value: string) => {
        setRegistration({...registration, [key]: value});
    }

    const removeError = (key: string) => {
        setRegistrationError({...registration_error, [`${key}_error`]: "", [`${key}_has_error`]: false});
    }

    const handleAccountType = (type: string) => {
        if(type === "dealer"){
            setRegistration({...registration, is_fabricator: false, is_distributor: true});
        }
        else if(type === "fabricator"){
            setRegistration({...registration, is_fabricator: true, is_distributor: false});
        }
    }

    const handleRegistration = () => {
        let error: boolean = false;
        const new_registration_error = {...registration_error};

        if(!validateEmail(registration.email)){
            new_registration_error.email_error = "Enter a valid email address";
            new_registration_error.email_has_error = true;
            error = true;
        }

        if(!validatePhoneNumber(registration.phone_number)){
            new_registration_error.phone_number_error = "Enter a valid phone number";
            new_registration_error.phone_number_has_error = true;
            error = true;
        }

        if(registration.first_name === ""){
            new_registration_error.first_name_error = "Must enter a first name";
            new_registration_error.first_name_has_error = true;
            error = true;
        }

        if(registration.last_name === ""){
            new_registration_error.last_name_error = "Must enter a last name";
            new_registration_error.last_name_has_error = true;
            error = true;
        }

        if(registration.business_name === ""){
            new_registration_error.business_name_error = "Must enter a business name";
            new_registration_error.business_name_has_error = true;
            error = true;
        }

        if(registration.address === ""){
            new_registration_error.address_error = "Must enter an address";
            new_registration_error.address_has_error = true;
            error = true;
        }

        if(registration.city === ""){
            new_registration_error.city_error = "Must enter a city";
            new_registration_error.city_has_error = true;
            error = true;
        }

        if(registration.postal_code === ""){
            new_registration_error.postal_code_error = "Must enter a postal code";
            new_registration_error.postal_code_has_error = true;
            error = true;
        }

        setRegistrationError(new_registration_error);
        
        if(error){
            return;
        }  
        
        dispatch(register(registration));
    }

    const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Escape")
        ) {
            setOpen(false);
        }
    };

    return (
        <Drawer
            onKeyDown={toggleDrawer}
            variant="temporary"
            anchor="bottom"
            open={open}>
                {
                    registration_complete ?
                    <Box 
                        sx={{ width: "auto", backgroundColor: "#212121", color: white_color, height: "60vh" }}
                        role="presentation">
                        <Grid container sx={{padding: "5%"}}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    Thank you for registering! Please check your email for your confirmation.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box> :
                    <Box 
                        sx={{ width: "auto", backgroundColor: "#212121", color: white_color, height: "60vh" }}
                        role="presentation">
                        <IconButton 
                            onClick={() => setOpen(false)}
                            sx={{position: "absolute", top: 25, right: 50}}>
                            <CloseIcon sx={{color: white_color}} />
                        </IconButton>
                        <Grid container sx={{padding: "5%"}}>
                            <Grid item xs={12}>
                                <Box display="flex" alignItems="center">
                                    <Typography variant="body2" sx={{color: error_color}}>
                                        {error} 
                                    </Typography>
                                    {
                                        offer_reset ? 
                                        <Typography 
                                            variant="body2"
                                            sx={{color: primary_color, cursor: "pointer", marginLeft: 0.5}}
                                            onClick={handlePasswordReset}>
                                            Reset password?
                                        </Typography> : 
                                        null
                                    }
                                    
                                </Box>
                            
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl>
                                    <FormLabel sx={{color: white_color}} id="Info">Name</FormLabel>      
                                    <FormHelperText error sx={formHelperTextStyle}>{registration_error.first_name_error}</FormHelperText>
                                    <TextField 
                                        sx={{
                                            color: white_color,
                                            fieldset: { borderColor: white_color },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: white_color
                                                }
                                            }
                                        }}
                                        InputProps={{ 
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            } 
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            }
                                        }}
                                        onFocus={() => removeError("first_name")}
                                        error={registration_error.first_name_has_error}
                                        size="small"
                                        label="First Name"
                                        id="first_name"
                                        onChange={e => saveString("first_name", e.target.value)} />
                                    <FormHelperText error sx={formHelperTextStyle}>{registration_error.last_name_error}</FormHelperText>
                                    <TextField 
                                        sx={{
                                            color: white_color,
                                            fieldset: { borderColor: white_color },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: white_color
                                                }
                                            }
                                        }}
                                        InputProps={{ 
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            } 
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            }
                                        }}
                                        onFocus={() => removeError("last_name")}
                                        error={registration_error.last_name_has_error}
                                        size="small"
                                        label="Last Name"
                                        id="last_name"
                                        onChange={e => saveString("last_name", e.target.value)} />
                                    <FormHelperText error sx={formHelperTextStyle}>{registration_error.business_name_error}</FormHelperText>
                                    <TextField 
                                        sx={{
                                            color: white_color,
                                            fieldset: { borderColor: white_color },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: white_color
                                                }
                                            }
                                        }}
                                        InputProps={{ 
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            } 
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            }
                                        }}
                                        onFocus={() => removeError("business_name")}
                                        error={registration_error.business_name_has_error}
                                        size="small"
                                        label="Business Name"
                                        id="business_name"
                                        onChange={e => saveString("business_name", e.target.value)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl>
                                    <FormLabel sx={{color: white_color}} id="address">Address</FormLabel>      
                                    <FormHelperText error sx={formHelperTextStyle}>{registration_error.address_error}</FormHelperText>
                                    <TextField 
                                        sx={{
                                            color: white_color,
                                            fieldset: { borderColor: white_color },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: white_color
                                                }
                                            }
                                        }}
                                        InputProps={{ 
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            } 
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            }
                                        }}
                                        onFocus={() => removeError("address")}
                                        error={registration_error.address_has_error}
                                        size="small"
                                        label="Business Address"
                                        id="address"
                                        onChange={e => saveString("address", e.target.value)} />
                                    <FormHelperText error sx={formHelperTextStyle}>{registration_error.city_error}</FormHelperText>
                                    <TextField 
                                        sx={{
                                            color: white_color,
                                            fieldset: { borderColor: white_color },
                                            marginBottom: 1.5,
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: white_color
                                                }
                                            }
                                        }}
                                        InputProps={{ 
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            } 
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            }
                                        }}
                                        onFocus={() => removeError("city")}
                                        error={registration_error.city_has_error}
                                        size="small"
                                        label="City"
                                        id="city"
                                        onChange={e => saveString("city", e.target.value)} />
                                    <TextField 
                                        sx={{
                                            color: white_color,
                                            fieldset: { borderColor: white_color },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: white_color
                                                }
                                            }
                                        }}
                                        InputProps={{ 
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            } 
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            }
                                        }}
                                        select
                                        defaultValue="AB"
                                        size="small"
                                        label="Province"
                                        id="province"
                                        onChange={e => saveString("province", e.target.value)}>
                                            <MenuItem key="AB" value="AB">
                                                AB
                                            </MenuItem>
                                            <MenuItem key="ON" value="ON">
                                                ON
                                            </MenuItem>
                                            <MenuItem key="BC" value="BC">
                                                BC
                                            </MenuItem>
                                            <MenuItem key="MB" value="MB">
                                                MB
                                            </MenuItem>
                                            <MenuItem key="SK" value="SK">
                                                SK
                                            </MenuItem>
                                            <MenuItem key="QC" value="QC">
                                                QC
                                            </MenuItem>
                                            <MenuItem key="NS" value="NS">
                                                NS
                                            </MenuItem>
                                            <MenuItem key="PE" value="PE">
                                                PE
                                            </MenuItem>
                                            <MenuItem key="NL" value="NL">
                                                NL
                                            </MenuItem>
                                            <MenuItem key="NB" value="NB">
                                                NB
                                            </MenuItem>
                                            <MenuItem key="YT" value="YT">
                                                YT
                                            </MenuItem>
                                            <MenuItem key="NU" value="NU">
                                                NU
                                            </MenuItem>
                                            <MenuItem key="NT" value="NT">
                                                NT
                                            </MenuItem>
                                        </TextField>
                                    <FormHelperText error sx={formHelperTextStyle}>{registration_error.postal_code_error}</FormHelperText>
                                    <TextField 
                                        sx={{
                                            color: white_color,
                                            fieldset: { borderColor: white_color },
                                            marginBottom: 1.5,
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: white_color
                                                }
                                            }
                                        }}
                                        InputProps={{ 
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            } 
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            }
                                        }}
                                        onFocus={() => removeError("postal_code")}
                                        error={registration_error.postal_code_has_error}
                                        size="small"
                                        label="Postal Code"
                                        id="postal_code"
                                        onChange={e => saveString("postal_code", e.target.value)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl>
                                    <FormLabel sx={{color: white_color}} id="contact">Contact</FormLabel>
                                    <FormHelperText error sx={formHelperTextStyle}>{registration_error.email_error}</FormHelperText>
                                    <TextField 
                                        sx={{
                                            color: white_color,
                                            fieldset: { borderColor: white_color },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: white_color
                                                }
                                            }
                                        }}
                                        InputProps={{ 
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            } 
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            }
                                        }}
                                        onFocus={() => removeError("email")}
                                        error={registration_error.email_has_error}
                                        size="small"
                                        label="Email"
                                        id="email"
                                        onChange={e => saveString("email", e.target.value)} />
                                    <FormHelperText error sx={formHelperTextStyle}>{registration_error.phone_number_error}</FormHelperText>
                                    <TextField 
                                        sx={{
                                            color: white_color,
                                            fieldset: { borderColor: white_color },
                                            "& .MuiOutlinedInput-root": {
                                                "&.Mui-focused fieldset": {
                                                    borderColor: white_color
                                                }
                                            }
                                        }}
                                        InputProps={{ 
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            } 
                                        }}
                                        InputLabelProps={{
                                            style: {
                                                fontSize: 16,
                                                color: white_color
                                            }
                                        }}onFocus={() => removeError("phone_number")}
                                        error={registration_error.phone_number_has_error}
                                        size="small"
                                        label="Phone: 555-555-5555"
                                        id="phone_number"
                                        onChange={e => saveString("phone_number", e.target.value)} />
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormControl>
                                    <FormLabel sx={{
                                        color: white_color, 
                                        "&.Mui-focused": {
                                            color: white_color
                                        }
                                    }} id="account_type">
                                        I am a
                                    </FormLabel>      
                                    <RadioGroup
                                        aria-labelledby="splash-group-application"
                                        defaultValue="dealer"
                                        name="splash-group-application"
                                        onChange={(e) => handleAccountType(e.target.value)} >
                                        <FormControlLabel 
                                            sx={{color: white_color}} 
                                            value="dealer" 
                                            control={<Radio sx={{
                                                color: white_color,
                                                '&, &.Mui-checked': {
                                                    color: white_color,
                                                }
                                            }} />} 
                                            label={
                                                <Typography variant="body1" sx={{color: white_color}}>
                                                    Dealer
                                                </Typography>
                                            } />
                                        <FormControlLabel 
                                            sx={{color: white_color}} 
                                            value="fabricator" 
                                            control={<Radio sx={{
                                                color: white_color,
                                                '&, &.Mui-checked': {
                                                    color: white_color,
                                                }
                                            }} />} 
                                            label={
                                                <Typography variant="body1" sx={{color: white_color}}>
                                                    Fabricator
                                                </Typography>
                                            } />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" sx={{marginTop: 10}}>
                                    {
                                        loading ? 
                                        <CircularProgress /> :
                                        <Button 
                                            onClick={handleRegistration}
                                            sx={{border: `1px solid ${white_color}`, color: white_color}}>
                                            Submit
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                </Box>
                }
                
        </Drawer>
    );
}
