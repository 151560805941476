import { User } from "types/authentication";
import { Action } from 'api/action_types/authentication';
import { ActionType } from 'api/action_types/authentication';

const USER: string = 'user';

interface State {
    user: object,
    loading: boolean,
    expired: boolean,
    login_success: boolean,
    offer_reset: boolean,
    registration_complete: boolean,
    account_confirmation_complete: boolean,
    password_reset: boolean,
    error: string
}

const initialState = {
    user: {},
    loading: false,
    expired: false,
    login_success: false,
    offer_reset: false,
    registration_complete: false,
    account_confirmation_complete: false,
    password_reset: false,
    error: ""
}

export const authenticationReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.LOGIN_SUCCESS:
            localStorage.setItem(USER, JSON.stringify(action.payload));
            getUser();
            return {
                ...state,
                loading: false,
                login_success: true,
                user: getUser(),
                error: ""
            }
        case ActionType.LOGIN_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                user: {}
            }
        case ActionType.REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                registration_complete: true,
                error: ""
            }
        case ActionType.REGISTER_PENDING:
            return {
                ...state,
                offer_reset: false,
                loading: true,
                error: ""
            }
        case ActionType.REGISTER_FAIL:
            return {
                ...state,
                error: action.payload,
                offer_reset: action.offer_reset,
                loading: false
            }
        case ActionType.GET_CONFIRMATION_SUCCESS:
            return {
                ...state,
                expired: false,
                loading: false
            }
        case ActionType.POST_CONFIRMATION_SUCCESS:
            return {
                ...state,
                account_confirmation_complete: true,
                expired: false,
                loading: false
            }
        case ActionType.CONFIRMATION_FAIL:
            return {
                ...state,
                error: action.payload,
                expired: action.expired,
                loading: false
            }
        case ActionType.CONFIRMATION_PENDING:
            return {
                ...state,
                loading: false
            }
        case ActionType.PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                loading: false,
                password_reset: true
            }
        case ActionType.PASSWORD_RESET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ActionType.PASSWORD_RESET_PENDING:
            return {
                ...state,
                loading: true
            }
        case ActionType.RESET_REGISTRATION_VALUES:
            return {
                ...state,
                registration_complete: false,
                offer_reset: false
            }
        case ActionType.RESET_PASSWORD_VALUES:
            return {
                ...state,
                loading: false,
                expired: false,
                offer_reset: false,
                password_reset: false,
                error: ""
            }
        default:
            return state;
    }
}

function getUser(): User{
    const storage = localStorage.getItem(USER);

    if(storage){
        const user: User | null = JSON.parse(localStorage.getItem(USER) || "");
        if(!user){
            return {};
        }

        return user;
    }
    return {};
}

