import { Action } from 'api/action_types/ui';
import { ActionType } from 'api/action_types/ui';

interface State {
    transparent: boolean
}

const initialState = {
    transparent: false
}

export const uiReducer = (state: State = initialState, action: Action):State => {
    switch(action.type){
        case ActionType.NAVBAR_TRANSPARENCY:
            return {
                ...state,
                transparent: action.transparent
            }
        default:
            return state;
    }
}

