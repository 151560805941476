import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from 'hooks/api';
import { useAppSelector } from 'hooks/api';
import { postResetPassword } from "api/actions/password_reset";
import { resetPasswordValues } from "api/actions/password_reset";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { text_primary } from "styles/theme";
import { error_color } from "styles/theme";

export default function CompletePasswordReset() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { uuid } = useParams();
    const [password, setPassword] = useState<string>("");
    const [password2, setPassword2] = useState<string>("");
    const { loading, error, password_reset } = useAppSelector((state) => state.authentication);
    const [has_error, setHasError] = useState<boolean>(false);
    const [local_error, setLocalError] = useState<string>("");

    useEffect(() => {
        dispatch(resetPasswordValues());
    }, [dispatch]);

    const handleChangePassword = () => {
        if(password !== password2){
            setHasError(true);
            setLocalError("Passwords must match");
            return;
        }

        if(password.length < 8){
            setHasError(true);
            setLocalError("Password must be at least 8 characters");
            return;
        }

        if(uuid){
            dispatch(postResetPassword(
                {
                    password: password,
                    password2: password2
                },
                uuid
            ));
        }
    }

    return (
        <Box sx={{padding: "20vh"}}>
        {
            password_reset ?
            <Stack>
                <Typography variant="h6" sx={{paddingLeft: 3, color: text_primary, paddingBottom: 0.5}}>
                    Your password has been reset
                </Typography>
                <Button
                    onClick={() => navigate("localhost:3000/login", {replace: false})}
                    variant="contained" 
                    sx={{width: 200, marginTop: 1.5, marginLeft: 3}}>
                    Login
                </Button>
            </Stack> :
            <Stack>
                <Typography variant="h6" sx={{paddingLeft: 3, color: text_primary, paddingBottom: 0.5}}>
                    Enter your new password
                </Typography>
                {
                    has_error && local_error !== "" ? 
                    <Typography variant="body2" sx={{color: error_color, paddingLeft: 3}}>
                        {local_error}
                    </Typography> :
                    null
                }
                {
                    !has_error && error !== "" ?
                    <Typography variant="body2" sx={{color: error_color, paddingLeft: 3}}>
                        {error}
                    </Typography> :
                    null
                }
                <TextField 
                    type="password"
                    sx={{marginLeft: 3, marginBottom: 2, marginTop: 1.5, paddingTop: 0.5, width: 400}}
                    size="small"
                    error={has_error}
                    onChange={(e) => setPassword(e.target.value)}
                    label="Password" />
                <TextField 
                    type="password"
                    sx={{marginLeft: 3, marginBottom: 2, marginTop: 1.5, width: 400}}
                    size="small"
                    error={has_error}
                    onChange={(e) => setPassword2(e.target.value)}
                    label="Confirm Password" />
                    {
                        loading ?
                        <CircularProgress sx={{marginTop: 1.5, marginLeft: 3}}/> :
                        <Button 
                            onClick={handleChangePassword}
                            variant="contained" 
                            sx={{width: 200, marginTop: 1.5, marginLeft: 3}}>
                            Change Password
                        </Button>
                    }
                    
            </Stack>
        }
        </Box>
  );
}
